exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrador-asociados-js": () => import("./../../../src/pages/administrador/asociados.js" /* webpackChunkName: "component---src-pages-administrador-asociados-js" */),
  "component---src-pages-administrador-categorias-js": () => import("./../../../src/pages/administrador/categorias.js" /* webpackChunkName: "component---src-pages-administrador-categorias-js" */),
  "component---src-pages-administrador-clientes-js": () => import("./../../../src/pages/administrador/clientes.js" /* webpackChunkName: "component---src-pages-administrador-clientes-js" */),
  "component---src-pages-administrador-cupones-js": () => import("./../../../src/pages/administrador/cupones.js" /* webpackChunkName: "component---src-pages-administrador-cupones-js" */),
  "component---src-pages-administrador-direcciones-js": () => import("./../../../src/pages/administrador/direcciones.js" /* webpackChunkName: "component---src-pages-administrador-direcciones-js" */),
  "component---src-pages-administrador-entrada-productos-js": () => import("./../../../src/pages/administrador/entrada-productos.js" /* webpackChunkName: "component---src-pages-administrador-entrada-productos-js" */),
  "component---src-pages-administrador-historial-ordenes-js": () => import("./../../../src/pages/administrador/historial-ordenes.js" /* webpackChunkName: "component---src-pages-administrador-historial-ordenes-js" */),
  "component---src-pages-administrador-imagenes-js": () => import("./../../../src/pages/administrador/imagenes.js" /* webpackChunkName: "component---src-pages-administrador-imagenes-js" */),
  "component---src-pages-administrador-marcas-js": () => import("./../../../src/pages/administrador/marcas.js" /* webpackChunkName: "component---src-pages-administrador-marcas-js" */),
  "component---src-pages-administrador-ordenes-js": () => import("./../../../src/pages/administrador/ordenes.js" /* webpackChunkName: "component---src-pages-administrador-ordenes-js" */),
  "component---src-pages-administrador-productos-js": () => import("./../../../src/pages/administrador/productos.js" /* webpackChunkName: "component---src-pages-administrador-productos-js" */),
  "component---src-pages-administrador-promociones-js": () => import("./../../../src/pages/administrador/promociones.js" /* webpackChunkName: "component---src-pages-administrador-promociones-js" */),
  "component---src-pages-administrador-usuarios-js": () => import("./../../../src/pages/administrador/usuarios.js" /* webpackChunkName: "component---src-pages-administrador-usuarios-js" */),
  "component---src-pages-cambiar-contrasena-js": () => import("./../../../src/pages/cambiar-contraseña.js" /* webpackChunkName: "component---src-pages-cambiar-contrasena-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-recuperar-contrasena-js": () => import("./../../../src/pages/recuperar-contraseña.js" /* webpackChunkName: "component---src-pages-recuperar-contrasena-js" */),
  "component---src-pages-update-password-js": () => import("./../../../src/pages/update-password.js" /* webpackChunkName: "component---src-pages-update-password-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */)
}


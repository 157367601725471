//Importaciones globales. Afectan a todo en general.
import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';  // Para los estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Para que el dropdown y otros componentes JS funcionen


/* Estilos globales para Header, Body y Footer */
import "./src/styles/styles-global.css";
/* Estilos para la parte de login */
import "./src/styles/style-login.css";
/* Estilos para la parte del sidebar completo */
import "./src/styles/sidebar.css";
/* Estilos para la parte de las opciones y ajustes del perfil */
import "./src/styles/style-perfil.css"
/* Estilos para la parte de la tabla */
import "./src/styles/style-table.css"
/* Estilos para la parte de la paginacion */
import "./src/styles/style-paginacion.css"
/* Estilos para la parte del modal para el alta de los productos */
import "./src/styles/style-formulario-producto.css"
/* Estilos para la parte del modal de detalles */
import "./src/styles/styles-detalles-producto.css"

export const wrapRootElement = ({ element }) => {
    const domain = process.env.AUTH0_DOMAIN;
    const clientId = process.env.AUTH0_CLIENT_ID;
    const redirectUri = process.env.AUTH0_REDIRECT_URI;
  
    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={redirectUri}
        onRedirectCallback={(appState) => navigate(appState?.returnTo || `/`)}
      >
        {element}
      </Auth0Provider>
    )
  }
  